// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotRecordFound_Container__bR7OT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 35rem;\n}\n\n@media screen and (max-width: 991px) {\n  .NotRecordFound_Container__bR7OT {\n    min-height: 100vh;\n    height: 100%;\n    align-items: flex-start;\n    width: 100vw;\n  }\n  .NotRecordFound_Container__Img__v3JH2 {\n    height: auto;\n    width: 100%;\n    margin-top: 110px;\n  }\n}", "",{"version":3,"sources":["webpack://src/common/pages/NotRecordFound.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE;IACE,iBAAA;IACA,YAAA;IACA,uBAAA;IACA,YAAA;EACF;EACE;IACE,YAAA;IACA,WAAA;IACA,iBAAA;EACJ;AACF","sourcesContent":[".Container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 35rem;\n}\n\n@media screen and (max-width: 991px) {\n  .Container {\n    min-height: 100vh;\n    height: 100%;\n    align-items: flex-start;\n    width: 100vw;\n\n    &__Img {\n      height: auto;\n      width: 100%;\n      margin-top: 110px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "NotRecordFound_Container__bR7OT",
	"Container__Img": "NotRecordFound_Container__Img__v3JH2"
};
export default ___CSS_LOADER_EXPORT___;
