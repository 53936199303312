// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".countDown_CountDown__MQeRb > div {\n  font-size: 14px;\n  color: #3772ff;\n}\n\n.countDown_CountDown_Detail__2jYlR > div {\n  font-size: 14px;\n  color: #777E90 !important;\n}", "",{"version":3,"sources":["webpack://src/common/countDown/countDown.module.scss"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,cAAA;AAFJ;;AAME;EACE,eAAA;EACA,yBAAA;AAHJ","sourcesContent":["@import \"../styles/variable.scss\";\n\n.CountDown {\n  & > div {\n    font-size: 14px;\n    color: #3772ff;\n  }\n}\n.CountDown_Detail {\n  & > div {\n    font-size: 14px;\n    color: #777E90 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CountDown": "countDown_CountDown__MQeRb",
	"CountDown_Detail": "countDown_CountDown_Detail__2jYlR"
};
export default ___CSS_LOADER_EXPORT___;
