// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_EnableElement__37Zji {\n  display: flex;\n  z-index: 9999;\n}\n\n.styles_DisableElement__XscDl {\n  display: none;\n}", "",{"version":3,"sources":["webpack://src/common/styles/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".EnableElement {\n  display: flex;\n  z-index: 9999;\n}\n\n.DisableElement {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EnableElement": "styles_EnableElement__37Zji",
	"DisableElement": "styles_DisableElement__XscDl"
};
export default ___CSS_LOADER_EXPORT___;
