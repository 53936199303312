// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurrencyInput_input__Tq5YN {\n  width: 100%;\n}\n\n.CurrencyInput_errorInput__2ek1W {\n  color: #ff4d4f;\n  margin-top: 8px;\n  display: block;\n  line-height: 1rem;\n}\n\n.CurrencyInput_errorOnly__30Bhs {\n  margin-bottom: 0px;\n  height: 0px;\n  min-height: 0px;\n  max-height: 0px;\n}\n.CurrencyInput_errorOnly__30Bhs > div > div:first-child {\n  display: none;\n}", "",{"version":3,"sources":["webpack://src/common/Input/CurrencyInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AAEF;AADE;EACE,aAAA;AAGJ","sourcesContent":[".input {\n  width: 100%;\n}\n\n.errorInput {\n  color: #ff4d4f;\n  margin-top: 8px;\n  display: block;\n  line-height: 1rem;\n}\n.errorOnly {\n  margin-bottom: 0px;\n  height: 0px;\n  min-height: 0px;\n  max-height: 0px;\n  & > div > div:first-child {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "CurrencyInput_input__Tq5YN",
	"errorInput": "CurrencyInput_errorInput__2ek1W",
	"errorOnly": "CurrencyInput_errorOnly__30Bhs"
};
export default ___CSS_LOADER_EXPORT___;
