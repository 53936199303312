// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TooltipHeader_Icon__30RHs {\n  display: flex;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://src/components/TooltipHeader/index.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;AACJ","sourcesContent":[".Icon {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": "TooltipHeader_Icon__30RHs"
};
export default ___CSS_LOADER_EXPORT___;
