// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-theme=light] {\n  --BgParticipateLaunchpad: linear-gradient(180deg, #F9FAFB 0%, rgba(250, 251, 252, 0) 100%);\n}\n\n[data-theme=dark] {\n  --BgParticipateLaunchpad: linear-gradient(180deg, #23262F 0%, rgba(35, 38, 47, 0) 100%);\n}\n\n.Home_HomeContainer__2apKk {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  background-color: var(--headerBackgroundColor);\n}\n\n.Home_BgParticipateLaunchpad__31Bot {\n  background: var(--BgParticipateLaunchpad);\n}", "",{"version":3,"sources":["webpack://src/pages/home/Home.module.scss"],"names":[],"mappings":"AAAA;EACE,0FAAA;AACF;;AAEA;EACE,uFAAA;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,8CAAA;AAAF;;AAGA;EACE,yCAAA;AAAF","sourcesContent":["[data-theme=\"light\"] {\n  --BgParticipateLaunchpad: linear-gradient(180deg, #F9FAFB 0%, rgba(250, 251, 252, 0) 100%);;\n}\n\n[data-theme=\"dark\"] {\n  --BgParticipateLaunchpad: linear-gradient(180deg, #23262F 0%, rgba(35, 38, 47, 0) 100%);;\n}\n\n\n.HomeContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  background-color: var(--headerBackgroundColor);\n}\n\n.BgParticipateLaunchpad {\n  background: var(--BgParticipateLaunchpad);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeContainer": "Home_HomeContainer__2apKk",
	"BgParticipateLaunchpad": "Home_BgParticipateLaunchpad__31Bot"
};
export default ___CSS_LOADER_EXPORT___;
