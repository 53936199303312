// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list_IDOList__1ExBr {\n  margin: 16px;\n}\n\n.list_Pagination__3zdqT {\n  text-align: right;\n  margin: 5px 0px;\n}", "",{"version":3,"sources":["webpack://src/pages/user/ido-pool/list/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AACA;EACE,iBAAA;EACA,eAAA;AAEF","sourcesContent":[".IDOList {\n  margin: 16px;\n}\n.Pagination {\n  text-align: right;\n  margin: 5px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IDOList": "list_IDOList__1ExBr",
	"Pagination": "list_Pagination__3zdqT"
};
export default ___CSS_LOADER_EXPORT___;
